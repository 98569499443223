<template>
  <ion-page>
    <ion-content
      style="background: var(--client-bg-login-color) !important"
      class="ion-padding login-content"
      :class="bgClient"
    >
      <div class="flex-content">
        <img
          id="photo-fullscreen"
          :src="whappyClientAsset + 'img/logo-login.png' || 'img/logo.png'"
          @error="handleImgError"
        />
      </div>
      <ion-card class="card-login">
        <!--        <ion-card-header>-->
        <!--          <ion-card-title>SignIn</ion-card-title>-->
        <!--        </ion-card-header>-->
        <ion-card-content>
          <div class="ion-no-padding">
            <label
              class="w-full text-18"
              style="color: var(--text-login-color) !important"
              position="stacked"
              >User Name</label
            >
            <ion-input
              class="w-full mt-2"
              @ionFocus="setFocusTrue"
              @ionBlur="setFocusFalse"
              inputmode="email"
              v-model="userInfo.username"
            ></ion-input>
          </div>
          <div class="ion-no-padding my-4 position-relative">
            <label
              class="w-full text-18"
              style="color: var(--text-login-color) !important"
              position="stacked"
              >Password</label
            >
            <ion-input
              class="w-full mt-2"
              @ionFocus="setFocusTrue"
              @ionBlur="setFocusFalse"
              :type="passwordVisible ? 'text' : 'password'"
              v-model="userInfo.password"
            ></ion-input>
            <div class="eye-icon">
              <ion-icon
                v-if="passwordVisible"
                tabindex="-1"
                @click="togglePasswordVisibility"
                :icon="eye"
              ></ion-icon>
              <ion-icon
                v-else
                tabindex="-1"
                @click="togglePasswordVisibility"
                :icon="eyeOff"
              ></ion-icon>
            </div>
          </div>

          <div class="w-full flex justify-content-center">
            <button
              class="button-login mt-4 w-full text-16"
              expand="full"
              :disabled="userInfo.username == '' || userInfo.password == ''"
              @click="login()"
            >
              Login
            </button>
          </div>
        </ion-card-content>
      </ion-card>
      <div id="bottom-component" class="bottom-component">
        <div v-if="tastieraFocus"></div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonCard,
  // IonCardHeader,
  // IonCardTitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonContent,
  IonPage,
  toastController,
  loadingController,
  IonLoading,
} from "@ionic/vue";
import { mapGetters, mapActions } from "vuex";
import { eye, eyeOff } from "ionicons/icons";

import UserService from "../common/mixins/User";

import VueCookies from "vue-cookies";
import { API_MOCK_ENABLE, API_MOCK_PATH, API_URL } from "../common/config";
import axios from "axios";

export default {
  components: {
    IonCard,
    // IonCardHeader,
    // IonCardTitle,
    IonCardContent,
    IonContent,
    IonPage,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    toastController,
  },
  mixins: [UserService],
  created() {
    this.removeDebugCookie();
    console.log("clientName: ", this.whappyClientConfig.name);

    axios({
      // url: API_MOCK_PATH + 'setting.json',
      url: API_MOCK_ENABLE
        ? API_MOCK_PATH + "setting.json"
        : API_URL + "Setting",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      this.bgClient = "bg-" + res.data.ragione_sociale.replace(/\s/g, "");
    });
  },
  data() {
    return {
      bgClient: "",
      tastieraFocus: false,
      eye,
      eyeOff,
      passwordVisible: false,
      userInfo: {
        username: "",
        password: "",
      },
    };
  },
  computed: {
    ...mapGetters("auth", {
      loginStatus: "getLoginStatus",
    }),
  },
  methods: {
    ...mapActions("auth", {
      loginUser: "loginUser",
    }),
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    setFocusTrue() {
      this.tastieraFocus = true;
      this.scrollDown();
    },
    setFocusFalse() {
      this.tastieraFocus = false;
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    scrollDown() {
      document
        .querySelector("#bottom-component")
        .scrollIntoView({ behavior: "smooth" });
    },
    async showError() {
      const toast = await toastController.create({
        message: this.$t("login.error"),
        duration: 2000,
        color: "danger",
      });
      await toast.present();
    },
    async showOk() {
      const toast = await toastController.create({
        message: this.$t("login.success"),
        duration: 2000,
        color: "success",
      });
      await toast.present();
    },
    async login() {
      var md5 = require("md5");
      console.log("setting: --> : ", this.settings);
      this.presentLoading();
      if (this.settings.two_factor) {
        console.log("2 factor, ", this.settings.two_factor);
        if (this.userInfo.username && this.userInfo.password) {
          let newPass = md5(this.userInfo.password);

          let newUserInfo = {
            username: this.userInfo.username,
            password: newPass,
            tfCode: "REQUEST",
          };

          try {
            await this.loginUser(newUserInfo);
            this.dismissLoading();
            this.showOk();
            console.log("vai a conferma 2 factors");
            this.$router.push({
              name: "ConfermaDueFattori",
              params: { username: this.userInfo.username, password: newPass },
            });
          } catch (e) {
            this.dismissLoading();
            this.showError();
          }
        }
      } else if (this.userInfo.username && this.userInfo.password) {
        let newPass = md5(this.userInfo.password);

        let newUserInfo = {
          username: this.userInfo.username,
          password: newPass,
        };

        try {
          await this.loginUser(newUserInfo);
          this.dismissLoading();
          this.showOk();
        } catch (e) {
          this.dismissLoading();
          this.showError();
        }
        if (this.loginStatus === "success") {
          this.getCurrentUser({
            forceRefresh: true,
          }).then((res) => {
            if (res.NewPasswordRequired) {
              this.dismissLoading();
              this.$router.push("/changepassword");
            } else {
              this.dismissLoading();
              this.$router.push("/welcome");
            }
          });
        } else {
          this.dismissLoading();
          this.showError();
        }
      }
    },
    async presentLoading() {
      console.log("presentLoading login");

      window.loadingCPresenting = true;
      if (!window.loadingC) {
        window.loadingC = await loadingController.create({
          spinner: null,
          cssClass: "login-custom-loading",
          message: "Stiamo caricando le tue campagne...",

          // duration: 60000,
        });
      }
      if (!window.loadingCPresented) {
        await window.loadingC.present();
        window.loadingCPresented = true;
      }
    },
    async dismissLoading() {
      console.log("dismissLoading login");
      if (window.loadingCPresented) {
        await window.loadingC.dismiss();
        window.loadingCPresenting = false;
        window.loadingCPresented = false;
        // // Sic
        // const elements = document.getElementsByClassName('my-custom-loading');
        // while(elements.length > 0){
        //   elements[0].parentNode.removeChild(elements[0]);
        // }
      } else if (window.loadingCPresenting) {
        setTimeout(() => {
          this.dismissLoading();
        }, 100);
      }
    },
    removeDebugCookie() {
      console.log("debug mode:", VueCookies.get("debug"));
      VueCookies.remove("debug");
      console.log("debug mode:", VueCookies.get("debug"));
    },
  },
  watch: {
    isLoading: function (e) {
      console.log("checkLoading login", e);
      if (e) {
        this.presentLoading();
      } else {
        this.dismissLoading();
      }
    },
  },
};
</script>
<style scoped lang="scss">
ion-card {
  box-shadow: none;
}
.flex-content {
  margin: 0px auto;
  width: 100%;
  height: calc(100% - 400px);
  display: flex; /* Magic begins */
  flex-direction: column;
  justify-content: center; /* Center in main axis */
  align-items: center; /* Center in cross axis */
}

#photo-fullscreen {
  max-width: 100%;
  max-height: calc(100% - 100px);
}

.position-relative {
  position: relative;
}

.eye-icon {
  position: absolute;
  bottom: 3px;
  right: 17px;
  color: rgb(255, 255, 255);

  z-index: 2;
}
ion-icon {
  font-size: 2rem;
}

ion-item::part(native) {
  background-color: rgba(255, 255, 255, 0);
  color: var(--text-color-light);
  border-radius: 5px;
}

.label-stacked {
  font-size: 1.7rem;
  color: var(--client-color-text-button) !important;
  margin-bottom: 1rem;
}

ion-input {
  width: 97%;
  font-size: 1.2rem;
}

ion-button::part(native) {
  margin-top: 4rem;
  background-color: #fff;
  background-color: var(--ion-color-secondary);
  color: var(--client-color-text-button);
  font-size: 2rem !important;
  text-transform: none;
  border-radius: 0px;
  padding: 0.6rem 2rem;
  font-weight: bolder;
}

.bottom-component {
  width: 100%;
  padding-top: 50vh;
}

.bg-Axerta {
  --background: url("https://axerta.whappy.it/img/axerta_bg.jpg") no-repeat
    center center / cover;
}

.bg-YoubeCosmetics {
  --background: var(--client-ground1-color);
  ion-item[data-v-26084dc2]::part(native) {
    color: var(--ion-color-primary);
  }

  ion-icon {
    color: var(--ion-color-primary);
  }
}

.bg-CommercialProjectManagement {
  --background: var(--ion-color-primary);
}

ion-input {
  --background: #faf1ef;
  color: var(--ion-color-primary);
  width: 100%;
}
</style>
